<template>
  <div class="EMS">
    <!-- 背景 -->
    <div class="EMS_bg">
      <div class="EMS_bg_top">
        <the-nav></the-nav>
      </div>
      <div class="EMS_bg_bottom w1200">
        <div class="EMS_bg_bottom_left">
          <div class="EMS_bg_bottom_logo">
            <img src="../../assets/imgs/img-logo-a.png" alt="轻EMS" />
          </div>
          <h1>新一代云边一体智能EMS</h1>
          <h6>易用安全，高效智能的能量管理系统</h6>
          <div class="yuyue_btn" @click="booking">预约体验</div>
        </div>
        <div class="EMS_bg_bottom_right"></div>
      </div>
    </div>
    <!-- 1 -->
    <div class="EMS_box bg_white">
      <div class="EMS_box_con1 w1200">
        <div class="left">
          <img src="../../assets/imgs/img-ems-picture-a.png" alt="" />
        </div>
        <div class="right">
          <div class="common_title">
            <span class="cn_title">新一代云边一体设计</span>
          </div>
          <div class="right_description">
            摒弃传统本地化软件设计思路，云边一体无缝通信设计
            支持单站运行，云端监管备份，多站集控协同。
            云端金融级大数据平台架构，海量数据处理，性能更强，安全性更高。
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="EMS_box bg_image">
      <div class="EMS_box_con1 w1200">
        <div class="right" style="margin-right: 108px">
          <div class="common_title">
            <span class="cn_title" style="color: #fff">远程遥控/遥调</span>
          </div>
          <div
            class="right_description"
            style="color: rgba(255, 255, 255, 0.85)"
          >
            可在云端进行PCS/空调策略调整，实现少人/无人值守。紧急状况下遥控系统启停，无需奔赴现场，及时保障电站安全。
          </div>
        </div>
        <div class="left" style="margin-right: 0">
          <img src="../../assets/imgs/img-ems-picture-b.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="EMS_box bg_gray">
      <div class="EMS_box_con3 w1200">
        <div class="common_title">
          <span class="cn_title">多种策略支持</span>
          <span class="en_title">Multiple policy support</span>
        </div>
        <div class="grid">
          <div
            class="grid_item"
            v-for="(item, index) in strategyList"
            :key="'strategy' + index"
          >
            <div class="left">
              <span>{{ item.name }}</span>
              <div class="hr"></div>
            </div>
            <div class="right">
              <img :src="item.url" alt="item.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="EMS_box bg_white">
      <div class="EMS_box_con4 w1200">
        <div class="common_title">
          <span class="cn_title">产品特点</span>
          <span class="en_title">Product features</span>
        </div>
        <div class="grid">
          <div
            class="grid_item bg_gray"
            v-for="(item, index) in productList"
            :key="'pro' + index"
          >
            <svg class="left" aria-hidden="true">
              <use :xlink:href="`#${item.svg}`"></use>
            </svg>
            <div class="right">
              <div class="right_title">{{ item.name }}</div>
              <div class="right_desc">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="EMS_box bg_gray" style="padding-bottom: 176px">
      <div class="EMS_box_con5 w1200">
        <div class="common_title">
          <span class="cn_title">产品价值</span>
          <span class="en_title">Product value</span>
        </div>
        <div class="grid">
          <div
            class="grid_item bg_white"
            v-for="(item, index) in valueList"
            :key="'value' + index"
          >
            <div class="grid_item_title">{{ item.name }}</div>
            <div class="grid_item_desc">{{ item.description }}</div>
            <div class="grid_item_img">
              <img :src="item.url" :alt="item.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <the-footer></the-footer>
  </div>
</template>

<script>
export default {
  name: "EMS",
  data() {
    return {
      // 策略
      strategyList: [
        {
          name: "削峰填谷",
          url: require("../../assets/imgs/img-ems-data-a.png"),
        },
        {
          name: "需量控制",
          url: require("../../assets/imgs/img-ems-data-b.png"),
        },
        {
          name: "离网备电",
          url: require("../../assets/imgs/img-ems-data-c.png"),
        },
        {
          name: "光储微网",
          url: require("../../assets/imgs/img-ems-data-d.png"),
        },
      ],
      // 产品特点
      productList: [
        {
          name: "智能策略",
          description:
            "基于人工智能的策略运行，最优化策略选择和切换，保障收益最大化。",
          svg: "a-icon-ems-a1x",
        },
        {
          name: "主动安全",
          description:
            "无缝衔接储电医生，常见电池异常定时诊断，为电站保安全延寿命。",
          svg: "a-icon-ems-b1x",
        },
        {
          name: "全量监控",
          description: "覆盖传统EMS盲区，消防，视频监控等多类设备全量接入。",
          svg: "a-icon-ems-c1x",
        },
        {
          name: "实施快捷",
          description:
            "广泛兼容主流设备厂商，配合智能调试验证技术，实施快人一步。",
          svg: "a-icon-ems-d1x",
        },
        {
          name: "加密安全",
          description: "采用SSL、VPN等安全技术，保障网络传输数据加密安全。",
          svg: "a-icon-guard-line-c1x",
        },
        {
          name: "易用好用",
          description:
            "互联网产品设计，简明易用，更支持手机APP，随时随地掌控电站。",
          svg: "a-icon-ems-f1x",
        },
      ],
      // 价值
      valueList: [
        {
          name: "储能EPC",
          description:
            "能量管理和系统信息化全部交给轻EMS，快速交付项目，省时省力更省成本，还可增加产品竞争力。",
          url: require("../../assets/imgs/img-ems-value-a.png"),
        },
        {
          name: "PCS厂商",
          description:
            "与轻EMS整合配套，智能融合，增加产品竞争力，拓展销售渠道。",
          url: require("../../assets/imgs/img-ems-value-b.png"),
        },
      ],
    };
  },
  methods: {
    booking() {
      // this.$store.commit("setState", { visible: true });
      window.open(
        `https://www.fqixin.cn/fsh5/smart-form/index.html?cardId=6570209edf8ff0034dc6d51a`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
.EMS {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &_bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 642px;
    background-color: #090c1b;
    // background-image: url("../../assets/imgs/img-home-bg.png");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 100% 100%;
    &_top {
      display: flex;
      justify-content: center;
      height: 80px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
    }
    &_bottom {
      flex: 1;
      display: flex;
      justify-content: space-between;
      padding-top: 48px;
      &_logo {
        width: 183px;
        height: 72px;
        margin-top: 48px;
        margin-bottom: 32px;
      }
      h1 {
        font-size: 40px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        margin-bottom: 16px;
      }
      h6 {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: rgba(255, 255, 255, 0.45);
        margin-bottom: 80px;
      }
      .yuyue_btn {
        width: 187px;
        height: 56px;
        line-height: 56px;
        background-color: rgba(0, 198, 153, 0.15);
        border: 1px solid rgba(0, 198, 153, 0.45);
        border-radius: 2px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #00c699;
        cursor: pointer;
        &:hover {
          background-color: #00c699;
          color: #ffffff;
        }
      }
      &_right {
        width: 697px;
        height: 440px;
        background-image: url("../../assets/imgs/img-product-a.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }
  .bg_white {
    background-color: #ffffff;
  }
  .bg_gray {
    background-color: #f4f5f7;
  }
  .bg_image {
    background-image: url("../../assets/imgs/img-ph-blue-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }
  &_box {
    padding: 88px 0;
    .common_title {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 34px;
      .cn_title {
        font-size: 30px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .en_title {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
        margin-top: 8px;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        content: "";
        display: block;
        width: 210px;
        height: 2px;
        background-color: #e6e6e6;
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 3;
        content: "";
        display: block;
        width: 48px;
        height: 2px;
        background-color: #00c699;
      }
    }
    &_con1 {
      display: flex;
      justify-content: center;
      .left {
        width: 496px;
        height: 660px;
        // border: 1px solid red;
        margin-right: 108px;
      }
      .right {
        display: flex;
        flex-direction: column;
        width: 394px;
        margin-top: 146px;
        &_description {
          margin-top: 40px;
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
    &_con3 {
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 248px);
        grid-gap: 8px 8px;
        margin-top: 48px;
        &_item {
          display: flex;
          background-color: #ffffff;
          border-radius: 4px;
          padding: 16px 0;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 144px;
            border-right: 1px solid #e6e6e6;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            .hr {
              width: 16px;
              height: 2px;
              background-color: #00c699;
              margin-top: 16px;
            }
          }
          .right {
            flex: 1;
          }
        }
      }
    }
    &_con4 {
      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 166px);
        grid-gap: 8px 8px;
        margin-top: 48px;
        &_item {
          display: flex;
          padding: 24px 32px;
          .left {
            width: 56px;
            height: 56px;
            margin-top: 24px;
            margin-right: 32px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            &_title {
              padding-bottom: 16px;
              border-bottom: 1px solid #e6e6e6;
              font-size: 16px;
              font-family: PingFangSC, PingFangSC-Medium;
              font-weight: 600;
              text-align: left;
              color: rgba(0, 0, 0, 0.85);
            }
            &_desc {
              margin-top: 16px;
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: rgba(0, 0, 0, 0.45);
            }
          }
        }
      }
    }
    &_con5 {
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 376px;
        grid-column-gap: 8px;
        margin-top: 48px;
        &_item {
          position: relative;
          padding: 48px;
          &_title {
            font-size: 24px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: left;
            color: rgba(0, 0, 0, 0.85);
            padding-bottom: 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          }
          &_desc {
            margin-top: 24px;
            height: 56px;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: rgba(0, 0, 0, 0.45);
          }
          &_img {
            position: absolute;
            left: 48px;
            bottom: -100px;
            z-index: 3;
            width: 516px;
            height: 272px;
            // border: 1px solid red;
          }
        }
      }
    }
  }
}
</style>
